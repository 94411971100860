@import "../../../sequential_left_nav.less";
@import "../../../../../../styleguide/styles/Tree.less";
@import "./steps/us_privacy_steps.less";
@import "./steps/privacy_choices.less";
@import "./steps/opt_in_opt_out_events.less";
@import "./edit_regulation.less";
@import "./integrations_hub.less";
@import "./steps/summary.less";
@import "./../../consent/vl_lists.less";
@import "./steps/usnat_vendor_management.less";
@import "./preferences.less";
@import "./utility.less";

#avocado {
  .us-privacy-list-layout {
    h1,h2,h3,h4,h5,h6 {
      color: #434343;
      font-family: @primary-font;
    }
    h2 {
      padding-bottom: 10px;
      font-weight: 600;
    }
    h5 {
      padding-bottom: 30px;
      font-weight: 400;
    }
    .us-privacy-search-add {
      display: flex;
      justify-content: space-between;
      .spsg-input {
        width: 30%;
      }
      .add-new {
        margin-right: 80px;
      }
    }
  }

  .new-us-privacy-regulations-wizard {
    font-family: @primary-font;
    color: @primary-text-color;
    div.header {
      position: fixed;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px var(--ant-padding-md) ;
      background: #fff;
      height: 60px;
      border-bottom: var(--ant-line-width, 1px) solid var(--ant-color-split, #F2F2F2);
      box-shadow: 0px 2px 4px 0px rgba(0, 35, 11, 0.20);
      z-index: 4;
      .ant-breadcrumb{
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        li {
          &:not(:last-child) .ant-breadcrumb-link {
            text-decoration-line: none;
            padding: 0 var(--ant-padding-xxs);
            border-radius: var(--ant-border-radius-sm);
          }
          &:last-child {
            font-weight: 600;
          }
        }

     
      }
    }
    >div.content {
      display: flex;
      padding-top: 64px;
      @left-nav-width: 264px;
      .wizard-left-nav {
        width: @left-nav-width;
        background: #ffffff;
        border-right: 1px solid #d3d9dd;
        padding: var(--ant-padding);
        height: 100vh;
        .sequential-navigation-mixin();
      }
      .wizard-layout {
        display: flex;
        flex-direction: column;
        width: calc(100% - @left-nav-width);
        background: #fafafa;
        #wizard-step-component {
          width: 100%;
          height: calc(100vh - 120px);
          padding: var(--ant-padding-lg);
          overflow-y: scroll;
          .us-privacy-step-generics();
          .summary-page-mixin();
        }
        .bottom-nav {
          display: flex;
          justify-content: space-between;
          background: #FFF;
          align-items: center;
          position: fixed;
          bottom: 0;
          width: inherit;
          height: 56px;
          border-top: 1px solid var(--Colors-Neutral-Border-colorBorder, #D3D9DD);
          box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.12);
          button {
            margin: 0px var(--ant-margin-lg);
            &.ant-btn-primary{
              right: 0;
              position: absolute
            }
          }
        }
      }
      .privacy-choices-mixin();
      .opt-in-out-hooks-mixin();
      .vendor-management-mixin();
    }
  }
  .vl-list-mixin();

}

span.anticon-info-circle.usp-infotip {
  background: inherit !important;
  font-size: inherit;
  svg {
    color: var(--ant-color-icon);
  }
  &.warning {
    margin-right: 10px;
    svg {
      color: var(--ant-color-warning) !important;
    }
  }
  &.error-icon{
    svg{
      color: var(--ant-color-error) !important;
      bottom: 13px;
      left: 20%;
    }
  }
}

.spsg-infotip.usp-infotip {
  .ant-popover-inner{
    >.ant-popover-inner-content{
      line-height: 1.4em;
      max-width: 400px;
      font-size: 12px;
      ul {
        padding-inline-start: 10px;
      }
      .vendor-name-infotip{
        .vendor-id{
          padding: 0px 5px 0px 5px;
        }
      }
    }
  }
  .ant-popover-arrow {
    display:block;
  }
}

.framework-selection-radio-group {
  display: flex;
  justify-content: center;
  gap: var(--ant-margin-md, 20px);
  .ant-radio-wrapper {
    margin: 0;
    padding: var(--ant-padding-lg, 24px);
    border: 1px solid var(--ant-color-border, #D3D9DD);
    border-radius: 6px;
    position: relative;
    width: 218px;
    .ant-tag-orange {
      top: 0;
      right: 0;
      position: absolute;
      margin-right: 0;
      border-radius: 0 6px 0 6px;
    }
    .ant-space-item {
      text-align: center;
    }
    >span {
      padding: 0;
      &.ant-radio.ant-wave-target {
        display: none;
      }
      .isvg {
        height: 75px;
        width: 130px;
      }
    }
    &:hover {
      .ant-typography:not(.ant-typography-secondary) {
        font-weight: 600;
      }
      path {
        stroke: var(--ant-color-primary-hover);
      }
      .filled-us-map {
        path[fill="#8F8F8F"] {
          fill:  var(--ant-color-primary-hover);
        }
      }
    }
    &.ant-radio-wrapper-checked {
      border-color: var(--ant-color-primary-border-hover);
      .ant-typography:not(.ant-typography-secondary) {
        color: var(--ant-color-primary-active);
      }
      path {
        stroke: var(--ant-color-primary);
      }
      .filled-us-map {
        path[fill="#8F8F8F"] {
          fill:  var(--ant-color-primary);
        }
      }
    }
  }
}

.ellipse-icon {
  &.cyan-7 circle {
    fill: var(--ant-cyan-7);
  }
  &.magenta-6 circle {
    fill: var(--ant-magenta-6);
  }
  &.green-8 circle {
    fill: var(--ant-green-8)
  }
}
