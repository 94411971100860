@import "../../../properties_selection.less";
@import "./territories_selection.less";
@import "./language_translation.less";
@import "./usnat_vendor_management.less";
@import "../preferences.less";
.us-privacy-step-generics {
  .spsg-alert.ant-alert .ant-alert-content .ant-alert-message {
    font-size: 14px;
  }
  .data-field {
    margin: var(--ant-margin) 0px;
    .spsg-input{
      max-width: 350px;
      margin-top: 10px
    }
  }
  .step-header {
    display: flex;
    align-items: center;
  }
  .step-breif {
    margin-bottom: var(--ant-margin);
  }
  .step-error {
    color: @red-color;
  }
  .step-section{
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d3d9dd;
    // border-radius: 5px;
    margin-bottom: var(--ant-margin);

    &.no-margin-bottom {
      margin-bottom: 0;
    }
    &.padding-md {
      padding: var(--ant-padding-md);
    }
    .ant-layout.main-container-layout{
      display: flex;
      flex-direction: row;
      flex: 1;
    }
    &.validation-error {
      border: 1px solid @red-color;
      border-radius: 5px
    }
    &.no-border{
      border: 0px;
    }
    .empty-div{
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &.validation-error {
        border: 1px solid @red-color;
        border-radius: 5px
      }
    .section-content {
      display: flex;
      align-items: center;
      &.validation-error {
        border: 1px solid @red-color;
        border-radius: 5px
      }
      .properties_selection-mixin();
      .select-territories-section-mixin();
    }
    .section-header{
      margin: 15px 20px;
    }
  }
  .language-translation-mixin();    
  .vendor-management-mixin();
  .preferences-steps-mixin();
  
  .sharing-section {
    padding: 10px;
    width: 100%;
  }

  .top-margin {
    margin-top: 16px;
  }
  .right-margin {
    margin-right: var(--ant-margin-xs);
  }
}

.ant-modal-wrap.operation-confirmation.dismiss-error {
  .ant-modal{
    width: 730px !important;
  }
  .ant-modal-header {
    .ant-modal-title {
      font-weight: 600;
    }
  }
   .note {
    color: #434343;
    font-size: 14px;
    text-align: left;
    font: normal 600 14px/24px @primary-font;
  }
  .note-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dedede;
    border-radius: 6px;
    padding: 15px;
    margin-top: 15px;
    overflow-y: scroll;
    max-height: 80vh;
    .error-block{
      margin-top: 10px;
      .grid-box{
        display: grid;
        grid-template-columns: 15% 5% 80%;
        .reg-title{
          grid-column-start: 1;
        }
        >p{
          grid-column-start: 3;
        }
      }
    }
  }
  .bottom-note {
    margin-top: 10px;
    text-align: left;
    font: normal 14px/24px Open Sans;
    color: @primary-text-color;
  }
}

.ant-modal-wrap.edit-preferences-modal{

  .us-privacy-step-generics();
  &:not(.properties) {
    .ant-modal-body {
      padding: 16px;
    }
    .step-section{
      border: 0px
    }
  }
  &.language {
    .step-section {
      .language-translation-tabs  .translation-container {
        height: 40vh;
        .menu .ant-menu{
          max-height: calc(40vh - 45px);
        }

        // .content .ant-table-warpper .ant-table-body {
        //   height: ;
        // }
      }
    }
  }
  &.manage_preferences {
    .ant-modal-body {
      padding: 0 var(--ant-margin);
      .ant-tabs-nav {
        margin-bottom: var(--ant-margin);
      }
    }
  }
}
