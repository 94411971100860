@import '../../../../../../../styleguide/styleguide.less';

.select-territories-section-mixin{
  .singatory-id {
    margin: 15px 20px;
    >div {
      margin-top: 10px;
      .ant-tag-processing {
        height: 32px;
        line-height: 2;
      }
    }
    .name{
      color: #666
    }
  }
  .select-regions-mixin();
}

.ant-modal.signatory-modal {
  font-family: @primary-font;
  >label {
    margin-bottom: var(--ant-margin-xxs);
  }
  .signatory-name {
    color: var(--ant-color-text-tertiary);
    margin-top: 2px;
    .green{
    color: var(--ant-color-success)
    }
    &.error{
      color: var(--ant-color-error)
    }
    b{
      font-weight: 600;
    }
  }
  .signatory-input {
    display: flex;
    justify-content: flex-start;
    gap: var(--ant-margin-xs);
    .ant-input{
      width: 360px
    }
  }
  .ant-divider-horizontal {
    margin: var(--ant-margin-sm) 0;
  }
  .mspa-covered-transactions {
    >div {
      margin: 10px 0px;
    }
    >label{
      margin-bottom: 0px;
    }
    a:hover{
      text-decoration: underline;
    }
  }
}
