@import "../../../../../../stylesheets/dashboard/avocado/consent/vendor_list_wizard.less";

.vendor-management-mixin{
  .ant-table-wrapper.usnat-vendors-table {
    .ant-table-thead > tr > th {
      &:not(:last-child){
        border-right: 0px;
      }
      &::before {
        display: none;
      }
      &.privacy-choice-column {
        &:not(.bulk-edit){
          div.ant-table-filter-column{
            justify-content: flex-start !important;
            span.ant-table-column-title{
              flex: none !important;
            }
          }
        }
        div.bulk-edit-choices{
          >div:not(.spsg-select) {
            margin: 5px 5px 5px 0px;
            font-weight: bold;
          }
          .ant-select.spsg-select-multiple{
            width: 90%;
          }
        }
      }
      &.ant-table-cell-scrollbar{
        padding: 0px;
      }
    }
    .ant-table-tbody > tr {
      > td {
        background: transparent;
        font-size: 14px;
        line-height: 24px;
        border-color: #D3D9DD;
        &/*:not(:last-child)*/{
          border-right: 0px;
          border-left: 0px;
        }
        span.more-categories{
          background-color: #E9DFFF;
          padding: 5px;
          border-radius: 5px;
        }
        span.redirect svg {
          vertical-align: bottom;
          margin-left: 5px;
        }
        button{
          height: 34px;
          line-height: 0;
          &.icon{
            padding: 0px;
            height: 32px;
            width: 32px;
          }
        }
        &.vendor-name{
          cursor: default;
        }
        &.privacy-choice-column{
          .spsg-select .ant-select{
            width: 500px;
          }
        }
      }
    }
    &.min-height{
      .ant-table-body{
        table {
          height: 50vh;
        }
      }
    }
    &.th-white{
      .ant-table-thead > tr > th {
        background-color: transparent;
      }
      .ant-table-body{
        table {
          height: inherit;
        }
      }
    }
  }
  .vendor-management-table-header-bar {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px 10px 0px;
    div.spsg-input {
      width: 275px;
      .ant-input{
        margin-bottom: 0px;
      }
    }
    // >div.button-grp {
    //   display: flex;
    //   align-items: center;
    //   .bulk-actions{
    //     background: #FFFFFF 0% 0% no-repeat padding-box;
    //     border: 1px solid #DCDCDC;
    //     border-radius: 5px;
    //     display: flex;
    //     justify-content: flex-start;
    //     align-items: center;
    //     max-width: 400px;
    //     color: @primary-color;
    //     padding: 5px;
    //     height: 36px;
    //     button.icon {
    //       height: 30px;
    //       width: 30px;
    //       padding: 0px;
    //     }
    //     &.disabled{
    //       background-color: #EBEFF5;
    //       cursor: not-allowed;  
    //       color: #C0C6D3;
    //     }
    //   }
    //   button{
    //     margin-left: 5px;
    //     &.icon{
    //       width: 36px;
    //       padding: 9px 10px;
    //     }
    //   }
    // }
  }
  .ant-spin {
    display: flex;
    justify-content: center;
    padding: 10%;
    position: relative;
    left: 0;
  }
}

.ant-modal.usnat-vendor-management-modal{
  .vendor-management-mixin();
  .ant-table-wrapper.usnat-vendors-table.min-height{
    .ant-table-body{
      table {
        height: 200px;
      }
    }
  }
  .ant-tabs-content-holder{
    padding: 0px !important;
  }
  .vendor-management-table-header-bar{
    .button-grp{
      border: 1px solid #DAE4EB;
      border-radius: 3px;
      padding: 5px;
      >button{
        margin-right: 5px;
      }
    }
    &.import-from-vl{
      align-items: flex-end;
      justify-content: flex-start;
      >div, >button {
        margin-right: 50px;
        div.ant-select{
          display: block;
          width: 250px;
        }
      }
    }
    &.vendor-scan{
      flex-direction: column;
    }
    .scan-vendors-tabs-mixin();
    .vlVendorTypeBox{
      margin: 15px 0px;
    }
  }
  >.ant-modal-body{
    padding: 15px;
    .spsg-tabs.spsg-normal{
      .ant-tabs-nav{
        padding: 0px;
      }
    }
  }
  .ant-modal-footer{
    >div{
      width: 100%;
      .confirmation-footer{
        display: flex;
        flex: 1;
        text-align: left;
        background-color: #FBFFD6;
        margin: 8px;
        border-radius: 4px;
        font-size: 16px;
        font-family: Open sans;
        font-weight: 400;
        >div.icon-div{
          flex: 0;
          margin-top: 10px;
          font-size: 25px;
          width: 25px;
        }
        >div {
          display: flex;
          flex-direction: column;
          flex: 1;
          >div {
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
          }
          >.button-group{
            display: flex;
            justify-content: flex-end;
            margin: 10px;
            .cancel {
              margin-left: 0px;
            }
          }
          .warning-tag {
            padding: 3px 5px;
            margin-right: 5px;
            border: 1px solid #FFD2CF;
            border-radius: 6px;
            background: #FF910070 0% 0% no-repeat padding-box;
            font: normal normal 600 14px/19px Open Sans;
            white-space: normal;
            &.viewmore {
              background: #ff9100;
              cursor: pointer;
              color: #fff;
              &:hover{
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }
}
