.summary-page-mixin {
  .summary-components{
    height: calc(100vh - 300px);
    overflow-y: scroll;
    .step {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      overflow-x: clip;
      .container {
        background-color: var(--ant-color-bg-base);
        border: 1px solid var(--ant-color-border);
        border-radius: var(--ant-border-radius-lg);
        padding: var(--ant-padding);
        >.ant-divider {
          margin: var(--ant-margin-sm) -16px;
          width: calc(100% + 2 * var(--ant-margin));
        }
      }
      .anticon-select {
        rotate: y 180deg
      }
    }
  }

  .summary-header{
    position: sticky;
    top: 0;
    z-index: 999;
    height: 110px;
  }
}
