@secondary-chip-color: var(--ant-color-success-bg);
@success-color: var(--ant-color-success);
@red-color: var(--ant-color-error);
@subtle-red-color: var(--ant-color-error-bg);

.select-regions-mixin{
  .regions-section {
    display: flex;
    width: 100%;
    height: 50vh;
    margin: 0px 0px;
    align-items: flex-start;
    .regions-selection {
      border-right: 1px solid #d3d9dd;
      border-radius: 5px 0px 0px 5px;
      width: 30vw;
      height: 50vh;
      // .spsg-tree label.ant-checkbox-wrapper {
      //   margin-left: 32px;
      //   gap: 12px;
      // }
      // .ant-input-search {
      //   margin: var(--ant-margin-xs);
      //   width: 95%;
      // }
       
      .ant-tree {
        overflow-y: scroll;
        overflow-x: clip;
        height: calc(50vh - 103px);
        margin: var(--ant-margin-xs);
        // max-height: 325px;
        .ant-tree-node-content-wrapper {
          max-width: 230px;
          display: flex;
          flex-direction: row-reverse;
          .ant-tree-iconEle.ant-tree-icon__customize {
            padding-inline-start: var(--ant-margin-xs);
            width: fit-content;
          }
          .ant-tree-title {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        // &.do-not-indent{
        //   .ant-tree-node-content-wrapper {
        //     margin-left: -24px;
        //   }
        // }
      }
      // .ant-tree-checkbox {
      //   position: absolute;
      //   right: 0;
      // }
    }
    .selected-regions {
      padding: var(--ant-padding);
      padding-top: 0  ;
      width: calc(1000% - 300px);
      height: inherit;
      div.selected-regions-container{
        height: calc(100% - 48px);
        overflow-y: scroll;
        &.centered{
          position: relative;
          >div {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
          }
          color: #D9D9D9;
          font: normal normal 600 16px/60px Open Sans;
        }
      }
      div.heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 48px;
        padding: var(--ant-padding) 0;
        b.clear-all {
          cursor: pointer;
          color: @primary-color;
        }
      }
      .region-group {
        padding: 16px 0;
        border-bottom: 1px solid var(--ant-color-border-secondary);
        .group-name {
          color: @primary-color;
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .region-selectot-radio-btns {
            .ant-radio-wrapper {
              &.ant-radio-wrapper-checked {
                .ant-radio-inner {
                  border-color: var(--ant-color-success-border);
                  &::after {
                    background-color: var(--ant-color-success);
                  }
                }
              }
              &:last-child.ant-radio-wrapper-checked {
                .ant-radio-inner {
                  border-color: var(--ant-color-error-border);
                  &::after {
                    background-color: var(--ant-color-error);
                  }
                }
              }
              &:hover {
                .ant-radio-inner {
                  border-color: var(--ant-color-success-border-hover);
                }
              }
              &:last-child:hover {
                .ant-radio-inner {
                  border-color: var(--ant-color-error-border-hover);
                }
              }
            }
          }
          svg {
            opacity: 0.7;
          }
          .ant-typography{
            font-size: 12px;
            font-weight: 400;
            line-height: 20px
          }
        }
        .group-regions {
          display: flex;
          flex-wrap: wrap;
          row-gap: 12px;
        }
        .spsg-chip {
          &.green-chips{
            border-color: @secondary-chip-color;
            background: @secondary-chip-color;
            span.anticon {
              background: @success-color;
              svg {
                color: #fff;
              }
            }
          }
          &.red-chips{
            border-color: @subtle-red-color;
            background: @subtle-red-color;
            span.anticon {
              background: @red-color;
              svg {
                color: #fff;
              }
            }
          }
        }
        button.spsg-btn {
          color: #fff;
          height: 34px;
          line-height: 0;
          &.green-btn {
            background: @success-color;
            border: 1px solid @success-color;
          }
          &.red-btn {
            background: @red-color;
            border: 1px solid @red-color;
          }
        }
      }
    }
    // .ant-divider-horizontal {
    //   margin: 8px 0;
    // }
  }
}
